<template>
  <div class="goodsOwnerManage">
    <div class="basucInfoBox">
      <el-form
        :model="basicInfoForm"
        :rules="vehicleRule"
        ref="basicInfoForm"
        label-width="190px"
        inline
      >
        <!-- <div class="title">发票类型 / 业务类型</div>
        <el-form-item v-for="(item,index) in selectlist" :key="index" :label="`${item.Name}：`">
          <div style="width:326px;">
            <div v-for="(lebel,inx) in item.lebel" :key="inx">
              {{lebel}}
            </div>
            <div>需提供证件照：{{item.LicenseName.toString()}}</div>
          </div>
        </el-form-item> -->
        <!-- <div style="display: flex;flex-wrap: wrap;">
          <div style="height:40px;line-height: 42px;color: #606266;"><span style="color: #FF0000">*</span> 请选择类型：</div>
          <el-form-item v-for="(item,index) in selectlist" :key="index" :label="`${item.Name}：`">
            <div style="width:380px">
              <div style="width: 100%;display: flex;justify-content: space-between;">
                <el-cascader
                  :ref="`tos${index}`"
                  :props="basicInfoForm[`tos${index}`]"
                  v-model="basicInfoForm[`tos${index}`].tos"
                  collapse-tags
                  style="width:326px;"
                  :placeholder="basicInfoForm[`tos${index}`].defaultSelect"
                  :placeholder-style="basicInfoForm[`tos${index}`].defaultSelect == '请选择业务类型'?'color:#DCDFE6;':'color:#000;'"
                  @change="cascaderChange">
                </el-cascader>
                <el-button type="danger" circle icon="el-icon-delete" @click="delType(item,index)"></el-button>
              </div>
              <div>需提供证件照：{{item.LicenseName.toString()}}</div>
            </div>
          </el-form-item>
        </div> -->
        <!-- 货主认证信息 -->
        <div class="title">货主认证信息</div>
        <div class="content">
          <div class="upBox">
            <UploadImg
              :isDelete="true"
              :loading="authForm.legalPersonIDCardOn.loading"
              :uploaded="authForm.legalPersonIDCardOn.uploaded"
              :picUrl="authForm.legalPersonIDCardOn.picUrl"
              @update:file="updateFile"
              @change="
                uploadLegalPersonIDCardOn(
                  authForm.legalPersonIDCardOn,
                  'legalPersonIName',
                  'legalPersonIIDCard'
                )
              "
              @deleteImg="deleteImg(authForm.legalPersonIDCardOn)"
            ></UploadImg>
            <div class="upText">
              <span class="required">*</span> 法人身份证(正面)
            </div>
          </div>
          <div class="upBox">
            <UploadImg
              :isDelete="true"
              :loading="authForm.legalPersonIDCardBack.loading"
              :uploaded="authForm.legalPersonIDCardBack.uploaded"
              :picUrl="authForm.legalPersonIDCardBack.picUrl"
              @update:file="updateFile"
              @change="
                uploadLegalPersonIDCardBack(
                  authForm.legalPersonIDCardBack,
                  'legalPersonlIDCardDate',
                  'LegalPersonalIDCardStartDate'
                )
              "
              @deleteImg="deleteImg(authForm.legalPersonIDCardBack)"
            ></UploadImg>
            <div class="upText">
              <span class="required">*</span> 法人身份证(反面)
            </div>
          </div>
          <div class="upBox">
            <UploadImg
              :isDelete="true"
              :loading="authForm.cfoidCard.loading"
              :uploaded="authForm.cfoidCard.uploaded"
              :picUrl="authForm.cfoidCard.picUrl"
              @update:file="updateFile"
              @change="
                uploadLegalPersonIDCardOn(
                  authForm.cfoidCard,
                  'CFOName',
                  'CFOIDCardNo'
                )
              "
              @deleteImg="deleteImg(authForm.cfoidCard)"
            >
            </UploadImg>
            <div class="upText">
              <span class="required">*</span> 财务经办人身份证(正面)
            </div>
          </div>
          <div class="upBox">
            <UploadImg
              :isDelete="true"
              :loading="authForm.cfoidCardBack.loading"
              :uploaded="authForm.cfoidCardBack.uploaded"
              :picUrl="authForm.cfoidCardBack.picUrl"
              @update:file="updateFile"
              @change="
                uploadLegalPersonIDCardBack(
                  authForm.cfoidCardBack,
                  'CFOIDCardValidDate',
                  'CFOIDCardStartDate'
                )
              "
              @deleteImg="deleteImg(authForm.cfoidCardBack)"
            >
            </UploadImg>
            <div class="upText">
              <span class="required">*</span> 财务经办人身份证(反面)
            </div>
          </div>
        </div>
        <div class="content" style="margin: 26px 0 0 0">
          <div class="upBox">
            <UploadImg
              :isDelete="true"
              :loading="authForm.businessLicense.loading"
              :uploaded="authForm.businessLicense.uploaded"
              :picUrl="authForm.businessLicense.picUrl"
              @update:file="updateFile"
              @change="uploadBusinessLicense(authForm.businessLicense)"
              @deleteImg="deleteImg(authForm.businessLicense)"
            ></UploadImg>
            <div class="upText">
              <span class="required">*</span> 营业执照(原件三合一正本)
            </div>
          </div>
          <div class="upBox" v-if="authForm.signet.picUrl">
            <UploadImg
              :isDelete="true"
              :loading="authForm.signet.loading"
              :uploaded="authForm.signet.uploaded"
              :picUrl="authForm.signet.picUrl"
              @update:file="updateFile"
              @change="uploadSignet(authForm.signet)"
              @deleteImg="deleteImg(authForm.signet)"
            >
            </UploadImg>
            <div class="upText">企业印章</div>
          </div>
          <div class="upBox" v-if="authForm.legalPersonBankCard.picUrl">
            <UploadImg
              :isDelete="true"
              :loading="authForm.legalPersonBankCard.loading"
              :uploaded="authForm.legalPersonBankCard.uploaded"
              :picUrl="authForm.legalPersonBankCard.picUrl"
              @update:file="updateFile"
              @change="uploadLegalPersonBankCard(authForm.legalPersonBankCard)"
              @deleteImg="deleteImg(authForm.legalPersonBankCard)"
            >
            </UploadImg>
            <div class="upText">法人银行卡</div>
          </div>
        </div>
        <div class="upBox" v-if="invoiceImgList[0].picUrl">
          <img
            class="upDiv"
            @click="flag.uploadInvoiceDialog = true"
            src="@/assets/image/upload.png"
          />
          <div class="upText">其他证件(最多10份)</div>
        </div>
      </el-form>
      <el-form
        :model="basicInfoForm"
        :rules="vehicleRule"
        ref="basicInfoForm"
        label-width="195px"
        inline
      >
        <div class="title">货主认证信息</div>
        <div class="content content1">
          <el-form-item label="法人姓名" prop="legalPersonIName">
            <el-input
              disabled
              v-model="basicInfoForm.legalPersonIName"
              placeholder="请输入法人姓名"
              onkeyup="value=value.replace(/[\d]/g,'') "
              onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[\d]/g,''))"
              maxlength="15"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="法人身份证号" prop="legalPersonIIDCard">
            <el-input
              disabled
              v-model="basicInfoForm.legalPersonIIDCard"
              placeholder="请输入法人身份证号"
            ></el-input>
          </el-form-item>
          <el-form-item label="法人手机号码" prop="legalPersonlPhone">
            <el-input
              disabled
              v-model="basicInfoForm.legalPersonlPhone"
              maxlength="11"
              placeholder="请输入法人手机号码"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="法人身份证起始日期"
            prop="LegalPersonalIDCardStartDate"
          >
            <el-date-picker
              disabled
              style="width: 200px"
              v-model="basicInfoForm.LegalPersonalIDCardStartDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择法人身份证有效期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="法人身份证有效期" prop="legalPersonlIDCardDate">
            <el-date-picker
              disabled
              style="width: 200px"
              :picker-options="pickerOptions"
              v-model="basicInfoForm.legalPersonlIDCardDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择法人身份证有效期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="法人银行卡号" prop="LegalPersonBankCardNo">
            <el-input
              disabled
              v-model="basicInfoForm.LegalPersonBankCardNo"
              placeholder="请输入法人银行卡号"
            ></el-input>
          </el-form-item>
          <el-form-item label="财务经办人姓名" prop="CFOName">
            <el-input
              disabled
              v-model="basicInfoForm.CFOName"
              placeholder="请输入经办人姓名"
              onkeyup="value=value.replace(/[\d]/g,'') "
              onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[\d]/g,''))"
              maxlength="15"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="财务经办人身份证号" prop="CFOIDCardNo">
            <el-input
              disabled
              v-model="basicInfoForm.CFOIDCardNo"
              placeholder="请输入财务经办人身份证号"
            ></el-input>
          </el-form-item>
          <el-form-item label="财务经办人手机号码" prop="CFOPhone">
            <el-input
              disabled
              v-model="basicInfoForm.CFOPhone"
              maxlength="11"
              placeholder="请输入财务经办人手机号码"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="财务经办人身份证起始日期"
            prop="CFOIDCardStartDate"
          >
            <el-date-picker
              disabled
              style="width: 200px"
              v-model="basicInfoForm.CFOIDCardStartDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择财务经办人身份证有效期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item
            label="财务经办人身份证有效期"
            prop="CFOIDCardValidDate"
          >
            <el-date-picker
              disabled
              style="width: 200px"
              :picker-options="pickerOptions"
              v-model="basicInfoForm.CFOIDCardValidDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择财务经办人身份证有效期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="企业性质" prop="BenefitWay">
            <el-select
              disabled
              v-model="basicInfoForm.BenefitWay"
              placeholder="请选择企业性质"
              style="width: 200px"
            >
              <el-option
                v-for="item in beneficiary"
                :key="item.Code"
                :label="item.Name"
                :value="item.Code"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <!-- <div class="content content1" style="justify-content: left;">
          <el-button type="primary" @click="flag.isBeneficiary = true">编辑受益人</el-button>
        </div> -->
        <!-- 企业信息 -->
        <div class="title">企业信息</div>
        <el-form-item label="企业名称" prop="enterpriseFullName">
          <el-input
            disabled
            v-model="basicInfoForm.enterpriseFullName"
            placeholder="请输入企业名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="统一社会信用代码" prop="enterpriseCreditCode">
          <el-input
            disabled
            v-model="basicInfoForm.enterpriseCreditCode"
            placeholder="请输入统一社会信用代码"
          ></el-input>
        </el-form-item>
        <el-form-item label="营业执照注册日期" prop="enterpriseRegisterDate">
          <el-date-picker
            disabled
            :picker-options="pickerBegin"
            v-model="basicInfoForm.enterpriseRegisterDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择营业执照注册日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="营业执照有效日期" prop="enterpriseEffectiveDate">
          <el-date-picker
            disabled
            :picker-options="pickerOptions"
            v-model="basicInfoForm.enterpriseEffectiveDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择营业执照有效日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="法定代表人" prop="legalPersonIName">
          <el-input
            disabled
            v-model="basicInfoForm.legalPersonIName"
            placeholder="请输入法定代表人"
          ></el-input>
        </el-form-item>
        <el-form-item label="营业执照住所" prop="enterpriseAddress">
          <el-input
            disabled
            v-model="basicInfoForm.enterpriseAddress"
            placeholder="请输入营业执照住所"
          ></el-input>
        </el-form-item>
        <el-form-item label="注册国家" prop="RegisterNation">
          <el-select
            disabled
            v-model="basicInfoForm.RegisterNation"
            placeholder="请选择注册国家"
          >
            <el-option
              v-for="item in nationality"
              :key="item.Code"
              :label="item.Name"
              :value="item.Code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="境内/境外" prop="DomesticOverseas">
          <el-select
            disabled
            v-model="basicInfoForm.DomesticOverseas"
            placeholder="请选择境内/境外"
          >
            <el-option
              v-for="item in domestic"
              :key="item.Code"
              :label="item.Name"
              :value="item.Code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="注册资本" prop="RegisteredCapital">
          <el-input
            disabled
            v-model="basicInfoForm.RegisteredCapital"
            placeholder="请输入注册资本"
          ></el-input>
        </el-form-item>
        <el-form-item label="税务登记号" prop="TAXID">
          <el-input
            disabled
            v-model="basicInfoForm.TAXID"
            placeholder="请输入税务登记号"
          ></el-input>
        </el-form-item>
        <el-form-item label="企业行业分类" prop="industry.area">
          <el-cascader
            ref="seleTc"
            v-model="basicInfoForm.industry.area"
            :props="basicInfoForm.industry"
            disabled
            placeholder="请选择企业行业分类"
            clearable
            @visible-change="industryChange"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="企业所在省市区" prop="address.area">
          <el-cascader
            ref="region"
            v-model="basicInfoForm.address.area"
            :props="basicInfoForm.address"
            disabled
            placeholder="请选择企业所在省市区"
            clearable
            @visible-change="addressChange"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="企业所在道路门牌" prop="road">
          <el-input
            disabled
            v-model="basicInfoForm.road"
            placeholder="请输入精确的街道、道路、门牌号"
          ></el-input>
        </el-form-item>
        <el-form-item label="经营范围" prop="BusinessScope">
          <el-input
            disabled
            v-model="basicInfoForm.BusinessScope"
            placeholder="请输入经营范围"
          ></el-input>
        </el-form-item>
        <div class="title">
          开票信息
          <!-- <div style="display:inline-block;color:red;">
            (注明：优先使用一般户，开户证明。无一般户填基本户，基本户开户证明)
          </div> -->
          <!-- <div style="position: absolute;right:30px;top:30%;cursor: pointer;" @click="flag.isCase = true">查看案例</div> -->
        </div>
        <div style="width: 100%; display: flex">
          <div style="width: 60%; display: flex">
            <div>
              <el-form-item label="发票抬头企业全名" prop="invoiceTitleName">
                <el-input
                  disabled
                  v-model="basicInfoForm.invoiceTitleName"
                  placeholder="请输入发票企业抬头"
                ></el-input>
              </el-form-item>
              <el-form-item label="纳税人识别号" prop="invoiceTitleCreditCode">
                <el-input
                  disabled
                  v-model="basicInfoForm.invoiceTitleCreditCode"
                  placeholder="请输入纳税人识别号"
                ></el-input>
              </el-form-item>
              <el-form-item label="企业联系电话" prop="invoiceTitlePhone">
                <el-input
                  disabled
                  v-model="basicInfoForm.invoiceTitlePhone"
                  placeholder="请输入企业联系电话"
                ></el-input>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="企业联系地址" prop="invoiceTitleAddress">
                <el-input
                  disabled
                  v-model="basicInfoForm.invoiceTitleAddress"
                  placeholder="请输入企业联系地址"
                ></el-input>
              </el-form-item>
              <el-form-item label="银行账户" prop="invoiceTitleBankerNumber">
                <el-input
                  disabled
                  v-model="basicInfoForm.invoiceTitleBankerNumber"
                  placeholder="请输入银行基本账号"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="银行账户开户行"
                prop="invoiceTitleBankerName"
              >
                <el-input
                  disabled
                  v-model="basicInfoForm.invoiceTitleBankerName"
                  placeholder="请输入银行账户开户行"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div style="width: 50%">
            <div
              class="upBox"
              style="margin-left: 10%; width: 278px; text-align: center"
            >
              <UploadImg
                :isDelete="true"
                :loading="authForm.accountLicence.loading"
                :uploaded="authForm.accountLicence.uploaded"
                :picUrl="authForm.accountLicence.picUrl"
                @update:file="updateFile"
                @change="uploadaccountLicence(authForm.accountLicence)"
                @deleteImg="deleteImg(authForm.accountLicence)"
              >
              </UploadImg>
              <div class="upText"><span class="required">*</span> 开户证明</div>
            </div>
          </div>
        </div>

        <!-- 基本存款账户 -->
        <div class="title">基本存款账户</div>
        <el-form-item label="基本存款账户账户名称" prop="BDAName">
          <el-input
            disabled
            v-model="basicInfoForm.BDAName"
            placeholder="请输入基本存款账户账户名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="基本存款账户账户号码" prop="BDANo">
          <el-input
            disabled
            v-model="basicInfoForm.BDANo"
            placeholder="基本存款账户账户号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="基本存款账户开户行" prop="BDABankName">
          <el-input
            disabled
            v-model="basicInfoForm.BDABankName"
            placeholder="请输入基本存款账户开户行"
          ></el-input>
        </el-form-item>
        <el-form-item label="基本存款账户编号" prop="BDANumber">
          <el-input
            disabled
            v-model="basicInfoForm.BDANumber"
            placeholder="基本存款账户编号"
          ></el-input>
        </el-form-item>
        <!-- 附件信息 -->
        <!-- <div class="title">附件信息</div>
        <div style="margin:0 0 20px 0;">
      
          <img style="cursor: pointer;" @click="GetGetAppendixList('btn')" src='@/assets/image/upFlie.png'/>
        </div> -->
      </el-form>
    </div>

    <!-- 上传证件dialog -->
    <el-dialog
      :modal="false"
      class="uploadImgDialog"
      title="上传其他证件(最多10份)"
      :visible.sync="flag.uploadInvoiceDialog"
      width="1000px"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <span slot="footer" class="dialog-footer">
        <!-- 上传文件 -->
        <div class="uploadImgWrap">
          <UploadImg
            class="uploadImg"
            v-for="(item, index) in invoiceImgList"
            :key="index"
            coverDesc="请上传证件"
            :loading="item.loading"
            :uploaded="item.uploaded"
            :picUrl="item.picUrl"
            :isDelete="true"
            @update:file="(e) => updateFiles(e, index)"
            @deleteImg="deleteImg(item, index)"
          >
          </UploadImg>
          <!-- <div class="add" v-if="invoiceImgList.length <= 9" @click="addInvoice">+</div> -->
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UploadImg from "@/components/commonCmpt/uploadImg";
import upLoadAccessory from "@/components/businessCmpt/upLoadAccessory";
import BeneficiaryList from "@/components/businessCmpt/beneficiarylist";
import {
  AddAllEnterPrise,
  getTCList,
  getTask,
  getTaskScene,
  GetGetAppendixList,
  removeEAppendix,
  UpLoadUpdateEAppendixInfo,
  GetAppendixID,
} from "@/api/goodsOwner/goodsOwnerManage/basicInfo";

import { getDataDict } from "@/api/common/common";
import { getArea, getAreaChildren } from "@/api/waybill/waybillManage/index";
import verify from "@/utils/verify";
import { GetenterPriseInfo } from "@/api/auditInfo/auditOwner/index";
let defaultData = "";
export default {
  props: {
    supplyId: "",
  },
  data() {
    return {
      // 受益人列表
      beneficiaryData: [
        {
          id: 1,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 2,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 3,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 4,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 5,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 6,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 7,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 8,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 9,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
        {
          id: 10,
          BeneficiaryName: "",
          BeneficiaryIDCardNo: "",
          BeneficiaryIDCardStartDate: "",
          BeneficiaryIDCardValidDate: "",
          BeneficiaryPhone: "",
          BeneficiaryIDCardUrl: "",
          BeneficiaryIDCardBackUrl: "",
        },
      ],
      // 分页数据
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 8, //每次请求的数量
        total: 0, //总条数
      },
      // 驳回弹窗
      rejectModel: false,
      // 驳回意见
      rejectValue: "",
      // 选中的客服
      ChineseRadio: "",
      // 发票类型数据
      selectlist: [],
      // 已选中的发票类型
      radio: "01",
      // 客服列表
      tableData: [],
      // 是否显示客服弹窗
      isDialog: false,
      // 搜索客服
      ChineseName: "",
      // 部门列表
      roleslist: [],
      // 选中的部门
      select: "",
      // 状态管理
      flag: {
        // 是否显示上传附件弹窗
        uploadInvoiceDialog: false,
        isExchange: false,
        // 二维码
        isQR: false,
        // 弹窗
        flaglabel: "",
        flagTitle: "",
        flagTextOne: "",
        flagTextTwo: "",
        flagTextThree: "",
        flagTextFour: "",
        // 改变icon
        addIcon: false,
        pactIcon: false,
        // 受益人弹窗
        isBeneficiary: false,
        // 是否开启操作受益人身份证信息
        isOpenBeneficiary: true,
        // 是否可操作附件
        isAccessory: true,
        // 查看案例
        isCase: false,
      },
      // 合同分页参数
      pactData: {
        page: 1, //当前所处的页码
        pagesize: 4, //每次请求的数量
        total: 0, //总条数
      },
      // 附件表格load
      pactLoading: false,
      // 附件数据
      pactlist: [],
      // 上传附件列表
      invoiceImgList: [
        {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
      ],
      cities: [],
      // 驳回意见
      rejectModel: false,
      // 选择日期 不能选择当日之后日期
      pickerBegin: {},
      // 选择日期 不能选择当日之前日期
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; // 设置选择今天以及今天之后的日
        },
      },
      // 提交的表单
      basicInfoForm: {
        // 行业
        industry: {
          area: [],
          value: "label",
          disabled: false,
          lazy: true,
          lazyLoad(node, resolve) {
            const { level } = node;
            if (level == 0) {
              getTCList({ type: 1, ParentId: "" }).then((res) => {
                res.TCInfo.forEach((item) => {
                  item.label = item.name;
                  item.leaf = level >= 1;
                });
                resolve(res.TCInfo);
              });
            } else if (level == 1) {
              getTCList({ type: 2, ParentId: node.data.id }).then((res) => {
                res.TCInfo.forEach((item) => {
                  item.label = item.name;
                  item.leaf = level >= 2;
                });
                resolve(res.TCInfo);
              });
            } else if (level == 2) {
              getTCList({ type: 3, ParentId: node.data.id }).then((res) => {
                res.TCInfo.forEach((item) => {
                  item.label = item.name;
                  item.leaf = level >= 3;
                });
                resolve(res.TCInfo);
              });
            } else {
              getTCList({
                type: 4,
                ParentId: node.data.id,
                name: node.data.name,
              }).then((res) => {
                res.TCInfo.forEach((item) => {
                  item.label = item.name;
                  item.leaf = true;
                });
                resolve(res.TCInfo);
              });
            }
          },
        },
        // 省市区
        address: {
          area: [],
          value: "label",
          disabled: false,
          lazy: true,
          lazyLoad(node, resolve) {
            const { level } = node;
            if (level == 0) {
              getArea().then((res) => {
                res.result[0].forEach((item) => {
                  item.label = item.fullname;
                  item.leaf = level >= 2;
                });
                resolve(res.result[0]);
              });
            } else {
              //如果是香港和澳门特别行政区就特别处理
              if (node.data.id == "810000" || node.data.id == "820000") {
                getAreaChildren({ id: node.data.id }).then((res) => {
                  res.result[0].forEach((item) => {
                    item.label = item.fullname;
                    item.leaf = true;
                  });
                  resolve(res.result[0]);
                });
              } else {
                getAreaChildren({ id: node.data.id }).then((res) => {
                  res.result[0].forEach((item) => {
                    item.label = item.fullname;
                    item.leaf = level >= 2;
                  });
                  resolve(res.result[0]);
                });
              }
            }
          },
        },
        road: "", //门牌号
        userID: 0,
        enterpriseContactsName: "", //企业联系人
        enterpriseContactsPhone: "", //企业联系人电话
        enterpriseCreditCode: "", //统一社会信用代码
        enterpriseFullName: "", //公司全称：发票抬头
        legalPersonIName: "", //法人代表
        legalPersonIIDCard: "", //法人身份证号
        legalPersonlPhone: "", //法人电话
        areaId: "", //地区标识
        placeAttribution: "", //所属地区
        enterpriseAddress: "", //营业执照住所
        enterpriseRegisterDate: "", //营业执照注册日期
        enterpriseEffectiveDate: "", //营业执照有效日期
        legalPersonlIDCardDate: "", //法人身份证有效日期
        rtBusinessLicense: "", //道路运输经营许可证编号
        defaultRate: "", //默认费率：国家规定的9个点
        bankerName: "", //开户银行:发票抬头（专票需要)
        bankerNumber: "", //银行账号：发票抬头（专票需要）
        status: 0, //企业状态:0未校验1校验通过2校验未通过
        remark: "", //错误原因：未通过原因
        invoiceType: "", //发票类型
        defaultRate1: "", //1%或3%票据的默认费率
        invoiceTitleName: "", //发票抬头企业全名
        invoiceTitleCreditCode: "", //纳税人识别号
        invoiceTitleBankerNumber: "", //银行基本账号
        invoiceTitleBankerName: "", //银行账户开户行
        invoiceTitleAddress: "", //企业联系地址
        invoiceTitlePhone: "", //企业联系电话
        formLabelAlign: "", //服务商开票面额
        RegisterNation: "", //注册国家
        DomesticOverseas: "", //境内/境外：字典表35
        RegisteredCapital: "", //注册资本
        TAXID: "", //税务登记号
        BusinessScope: "", //经营范围
        CFOName: "", //财务经办人姓名
        CFOIDCardNo: "", //财务经办人身份证号
        CFOIDCardValidDate: "", //财务经办人身份证有效期
        CFOPhone: "", //财务经办人手机号
        BeneficiaryName: "", //受益人姓名
        BeneficiaryIDCardNo: "", //受益人身份证号
        BeneficiaryIDCardValidDate: "", //受益人身份证有效期
        BeneficiaryPhone: "", //受益人手机号
        BenefitWay: "", //企业性质：字典表36
        BDAName: "", //基本存款账户名称
        BDANo: "", //基本存款账户号码
        BDABankName: "", //基本存款账户开户行名称
        BDANumber: "", //基本存款账户编号
        LegalPersonBankCardNo: "", // 法人银行卡号
        LegalPersonalIDCardStartDate: "", // 法人身份证起始日期
        BeneficiaryIDCardStartDate: "", // 受益人身份证起始日期
        CFOIDCardStartDate: "", // 财务经办人身份证起始日期
      },
      // 表单校验
      vehicleRule: {
        CFOName: [
          {
            required: true,
            message: "请输入财务经办人姓名",
            trigger: "change",
          },
        ],
        CFOIDCardNo: [
          {
            required: true,
            message: "请输入财务经办人身份证号",
            trigger: "change",
          },
        ],
        CFOIDCardValidDate: [
          {
            required: true,
            message: "请选择财务经办人身份证有效期",
            trigger: "change",
          },
        ],
        CFOIDCardStartDate: [
          {
            required: true,
            message: "请选择财务经办人身份证起始日期",
            trigger: "change",
          },
        ],
        CFOPhone: [
          {
            required: true,
            message: "请输入财务经办人手机号",
            trigger: "change",
          },
        ],
        BenefitWay: [
          { required: true, message: "请选择企业性质", trigger: "change" },
        ],
        RegisterNation: [
          { required: true, message: "请输入注册国家", trigger: "change" },
        ],
        DomesticOverseas: [
          { required: true, message: "请输入境内/境外", trigger: "change" },
        ],
        RegisteredCapital: [
          { required: true, message: "请输入注册资本", trigger: "change" },
        ],
        TAXID: [
          { required: true, message: "请输入税务登记号", trigger: "change" },
        ],
        BusinessScope: [
          { required: true, message: "请输入经营范围", trigger: "change" },
        ],
        "industry.area": [
          { required: true, message: "请选择企业行业分类", trigger: "change" },
        ],
        road: [
          {
            required: true,
            message: "请输入精确的街道、道路、门牌号",
            trigger: "change",
          },
        ],
        "address.area": [
          {
            required: true,
            message: "请选择企业所在省市区",
            trigger: "change",
          },
        ],
        legalPersonIName: [
          { required: true, message: "请输入法人姓名", trigger: "change" },
        ],
        legalPersonIIDCard: [
          { required: true, message: "请输入法人身份证号", trigger: "change" },
        ],
        legalPersonlIDCardDate: [
          {
            required: true,
            message: "请选择法人身份证有效期",
            trigger: "change",
          },
        ],
        LegalPersonalIDCardStartDate: [
          {
            required: true,
            message: "请选择法人身份证起始日期",
            trigger: "change",
          },
        ],
        legalPersonlPhone: [
          { required: true, message: "请输入法人手机号码", trigger: "change" },
        ],
        enterpriseFullName: [
          { required: true, message: "请输入企业名称", trigger: "change" },
        ],
        enterpriseCreditCode: [
          {
            required: true,
            message: "请输入统一社会信用代码",
            trigger: "change",
          },
        ],
        enterpriseAddress: [
          { required: true, message: "请输入营业执照住所", trigger: "change" },
        ],
        enterpriseRegisterDate: [
          {
            required: true,
            message: "请选择营业执照注册日期",
            trigger: "change",
          },
        ],
        enterpriseEffectiveDate: [
          {
            required: true,
            message: "请选择营业执照有效日期",
            trigger: "change",
          },
        ],
        invoiceTitleName: [
          {
            required: true,
            message: "请输入发票抬头企业全名",
            trigger: "change",
          },
        ],
        invoiceTitleCreditCode: [
          { required: true, message: "请输入纳税人识别号", trigger: "change" },
        ],
        invoiceTitleBankerNumber: [
          { required: true, message: "请输入银行基本账号", trigger: "change" },
        ],
        invoiceTitleBankerName: [
          {
            required: true,
            message: "请输入银行账户开户行",
            trigger: "change",
          },
        ],
        invoiceTitleAddress: [
          { required: true, message: "请输入企业联系地址", trigger: "change" },
        ],
        invoiceTitlePhone: [
          { required: true, message: "请输入企业联系电话", trigger: "change" },
        ],
        formLabelAlign: [
          {
            required: true,
            message: "请输入服务商开票面额",
            trigger: "change",
          },
        ],
      },
      //当前正在操作的文件
      currentFile: "",
      // 证据图片集合
      authForm: {
        userID: 0,
        //法人身份证(正面)
        legalPersonIDCardOn: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //法人身份证(反面)
        legalPersonIDCardBack: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        // 财务经办人身份证正面
        cfoidCard: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        // 财务经办人身份证反面
        cfoidCardBack: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        // 受益人身份证正面
        beneficiaryID: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        // 受益人身份证反面
        beneficiaryIDBack: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        // 开户许可证
        accountLicence: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        // 企业印章
        signet: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //营业执照(原件三合一正本)照片
        businessLicense: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //道路经营许可证
        rTBusinessLicense: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //企业银行卡
        legalPersonBankCard: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        // 网络货运道路运输经营许可证
        businessLicenseURL: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        // 代开专票试点企业资格
        invoiceLicense: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //其他证件照
        oherCertificate: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
      },
      // 发票类型
      selectlist: [],
      // 已选中发票类型
      radio: "01",
      // 资料是否审核中
      dataAudit: false,
      // 数据库中获取已选中的地址
      initial: [],
      // 数据库中获取已选中的行业
      industry: [],
      // 需要的证件照
      TaskTypeList: "",
      // 是否有更新证件或上传证件
      isUpdate: true,
      // 境内/境外
      domestic: [],
      // 企业性质
      beneficiary: [],
      // 注册国家
      nationality: [],
      UserID: "",
    };
  },
  computed: {
    ...mapGetters(["getFullInfo"]),
  },
  methods: {
    isSuccess() {
      this.$confirm(
        "如修改了服务商信息，请先点击“保存”按钮将修改内容保留下来！!",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.isDialog = true;
      });
    },
    // 撤销修改
    undoModify() {
      this.$confirm("是否撤销以上信息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.getGoodsOwnerInfo();
      });
    },
    // 删除受益人
    delBeneficiary(item) {
      this.beneficiaryData.forEach((data) => {
        if (data.id == item.id) {
          data.BeneficiaryName = "";
          data.BeneficiaryIDCardNo = "";
          data.BeneficiaryIDCardStartDate = "";
          data.BeneficiaryIDCardValidDate = "";
          data.BeneficiaryPhone = "";
          data.BeneficiaryIDCardUrl = "";
          data.BeneficiaryIDCardBackUrl = "";
        }
      });
    },
    // 编辑/上传受益人身份证图片
    uplodanMode(item) {
      this.authForm.beneficiaryID.picUrl = item.BeneficiaryIDCardUrl;
      this.authForm.beneficiaryIDBack.picUrl = item.BeneficiaryIDCardBackUrl;
    },

    // 清除搜索条件
    clearInp() {
      this.pagination.page = 1;
      this.GetPlatFormCustomer();
    },
    // 搜索
    searchClick() {
      this.pagination.page = 1;
      this.GetPlatFormCustomer();
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e;
      this.GetPlatFormCustomer();
    },

    copy() {
      document.getElementById("copyMy").select();
      document.execCommand("copy");
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
    // 下载附件
    upPact(item) {
      window.location.href = item.FileUrl;
    },
    // 删除附件
    delPact(item) {
      this.$confirm("此操作将删除该附件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        removeEAppendix({ AppendixID: item.ID }).then((res) => {
          this.pactData.page = 1;
          this.GetGetAppendixList();
        });
      });
    },
    // 上传/更新附件
    fileUpload(event, item) {
      this.pactLoading = true;
      let file = event.target.files;
      if (file.length != 0) {
        // 获取最后一个.的位置
        let inx = file[0].name.lastIndexOf(".");
        // 获取后缀
        let ext = file[0].name.substr(inx + 1);
        if (
          [
            "txt",
            "doc",
            "xls",
            "ppt",
            "docx",
            "xlsx",
            "pptx",
            "rtf",
            "pdf",
          ].indexOf(ext.toLowerCase()) !== -1
        ) {
          let formData = new FormData();
          formData.append("PartyBUserID", this.UserID);
          formData.append("file", file[0]);
          formData.append("FTID", item.FTID);
          GetAppendixID({ AppendixID: item.ID })
            .then((res) => {
              UpLoadUpdateEAppendixInfo(formData).then((data) => {
                this.pactData.page = 1;
                this.GetGetAppendixList();
              });
            })
            .finally(() => {
              this.pactLoading = false;
              document.getElementById("inp").value = "";
            });
        } else {
          this.$message.error("格式错误，请重新上传");
          this.pactLoading = false;
        }
      } else {
        this.pactLoading = false;
      }
    },
    // 获取附件
    GetGetAppendixList(type) {
      let data = {
        PageIndex: this.pactData.page,
        PageSize: this.pactData.pagesize,
        UserID: this.supplyId,
      };
      GetGetAppendixList({ Json: JSON.stringify(data) }).then((res) => {
        if (type) {
          this.flag.isExchange = true;
        }
        this.pactData.total = res.data.TotalCount;
        this.pactlist = res.data.DataList;
      });
    },
    // 附件分页
    pactChange(e) {
      this.pactData.page = e;
      // 获取附件
      this.GetGetAppendixList();
    },

    // 设置当前组件正在操作的文件
    updateFiles(file, index) {
      //获取最后一个.的位置
      let inx = file.name.lastIndexOf(".");
      //获取后缀
      let ext = file.name.substr(inx + 1);
      if (
        [
          "png",
          "jpg",
          "jpeg",
          "bmp",
          "gif",
          "webp",
          "psd",
          "svg",
          "tiff",
        ].indexOf(ext.toLowerCase()) !== -1
      ) {
        this.invoiceImgList[index].file = file;
        this.invoiceImgList[index].picUrl = window.URL.createObjectURL(file);
        this.invoiceImgList[index].loading = true;
        this.isUpdate = false;
      } else {
        this.$message.error("格式错误，请重新上传");
        this.invoiceImgList[index].loading = false;
      }
    },
    // 删除已选中的图片
    deleteImg(item, index) {
      // 删除本地选择图片
      item.uploaded = false;
      item.loading = false;
      item.picUrl = "";
      this.invoiceImgList.splice(index, 1);
    },

    //增加一个附件上传组件
    addInvoice() {
      this.invoiceImgList.push({
        loading: false,
        uploaded: false,
        picUrl: "",
        file: "",
      });
    },
    // 已选中的行业是否有改动
    industryChange(e) {
      setTimeout(() => {
        let area = this.basicInfoForm.industry.area;
        let initial = this.industry;
        if (industry[0] != undefined) {
          if (e === false) {
            if (area.toString() == initial.toString()) {
              this.$refs[
                "seleTc"
              ].inputValue = `${initial[0]} / ${initial[1]} / ${initial[2]} / ${initial[3]}`;
            }
          }
        }
      }, 350);
    },
    // 已选中的地址是否有改动
    addressChange(e) {
      setTimeout(() => {
        let area = this.basicInfoForm.address.area;
        let initial = this.initial;
        if (industry[0] != undefined) {
          if (e === false) {
            if (area.toString() == initial.toString()) {
              this.$refs[
                "region"
              ].inputValue = `${initial[0]} / ${initial[1]} / ${initial[2]}`;
            }
          }
        }
      }, 350);
    },

    //删除图片
    deleteImg(field) {
      field.uploaded = false;
      field.loading = false;
      field.picUrl = "";
    },
    //设置当前组件正在操作的文件
    updateFile(file) {
      this.currentFile = file;
    },
    //获取基本信息
    getGoodsOwnerInfo() {
      GetenterPriseInfo({ UserID: this.supplyId }).then((res) => {
        if (res.baseInfo) {
          this.basicInfoForm.formLabelAlign = res.denomination[0].Denomination;
          this.basicInfoForm.LegalPersonalIDCardStartDate =
            res.baseInfo.LegalPersonalIDCardStartDate;
          this.basicInfoForm.legalPersonIName = res.baseInfo.LegalPersonIName;
          this.basicInfoForm.legalPersonIIDCard =
            res.baseInfo.LegalPersonIIDCard;
          this.basicInfoForm.legalPersonlIDCardDate =
            res.baseInfo.LegalPersonlIDCardDate;
          this.basicInfoForm.legalPersonlPhone = res.baseInfo.LegalPersonlPhone;
          this.basicInfoForm.enterpriseFullName =
            res.baseInfo.EnterpriseFullName;
          this.basicInfoForm.enterpriseCreditCode =
            res.baseInfo.EnterpriseCreditCode;
          this.basicInfoForm.enterpriseAddress = res.baseInfo.EnterpriseAddress;
          this.basicInfoForm.enterpriseRegisterDate =
            res.baseInfo.EnterpriseRegisterDate;
          this.basicInfoForm.enterpriseEffectiveDate =
            res.baseInfo.EnterpriseEffectiveDate;
          this.basicInfoForm.Remark = res.baseInfo.Remark;
          // 0未校验 1校验通过 2校验未通过 3撤销
          this.dataAudit = res.baseInfo.Status == 0 ? true : false;
          this.basicInfoForm.Status = res.baseInfo.Status;
          if (res.baseInfo.Province) {
            this.$refs[
              "region"
            ].inputValue = `${res.baseInfo.Province} / ${res.baseInfo.City} / ${res.baseInfo.County}`;
            this.basicInfoForm.address.area[0] = res.baseInfo.Province;
            this.basicInfoForm.address.area[1] = res.baseInfo.City;
            this.basicInfoForm.address.area[2] = res.baseInfo.County;
            this.initial[0] = res.baseInfo.Province;
            this.initial[1] = res.baseInfo.City;
            this.initial[2] = res.baseInfo.County;
          }
          this.basicInfoForm.road = res.baseInfo.Road;
          if (res.baseInfo.TCKind) {
            if (res.baseInfo.TCMid) {
              this.$refs[
                "seleTc"
              ].inputValue = `${res.baseInfo.TCKind} / ${res.baseInfo.TCBig} / ${res.baseInfo.TCMid} / ${res.baseInfo.TCSmall}`;
            } else {
              this.$refs[
                "seleTc"
              ].inputValue = `${res.baseInfo.TCKind} / ${res.baseInfo.TCBig}`;
            }
            this.basicInfoForm.industry.area[0] = res.baseInfo.TCKind;
            this.basicInfoForm.industry.area[1] = res.baseInfo.TCBig;
            this.basicInfoForm.industry.area[2] = res.baseInfo.TCMid;
            this.basicInfoForm.industry.area[3] = res.baseInfo.TCSmall;
            this.industry[0] = res.baseInfo.TCKind;
            this.industry[1] = res.baseInfo.TCBig;
            this.industry[3] = res.baseInfo.TCMid;
            this.industry[4] = res.baseInfo.TCSmall;
          }
          this.basicInfoForm.RegisteredCapital = res.baseInfo.RegisteredCapital;
          this.basicInfoForm.TAXID = res.baseInfo.TAXID;
          this.basicInfoForm.BusinessScope = res.baseInfo.BusinessScope;
          // 认证信息
          this.authForm.businessLicense.picUrl =
            res.confirmInfo.BusinessLicenseURL;
          this.authForm.rTBusinessLicense.picUrl =
            res.confirmInfo.RTBusinessLicenseURL;
          this.authForm.legalPersonIDCardOn.picUrl =
            res.confirmInfo.LegalPersonIDCardOnURL;
          this.authForm.legalPersonIDCardBack.picUrl =
            res.confirmInfo.LegalPersonIDCardBackURL;
          this.authForm.legalPersonBankCard.picUrl =
            res.confirmInfo.BankCardUrl;
          this.authForm.oherCertificate.picUrl = res.confirmInfo.OthersUrl;
          this.authForm.cfoidCard.picUrl = res.confirmInfo.CFOIDCardUrl;
          this.authForm.cfoidCardBack.picUrl = res.confirmInfo.CFOIDCardBackUrl;
          this.authForm.beneficiaryID.picUrl =
            res.confirmInfo.BeneficiaryIDCardUrl;
          this.authForm.beneficiaryIDBack.picUrl =
            res.confirmInfo.BeneficiaryIDCardBackUrl;
          this.authForm.accountLicence.picUrl =
            res.confirmInfo.AccountLicenceUrl;
          this.authForm.signet.picUrl = res.confirmInfo.OfficialSealUrl;
          // 发票抬头
          this.basicInfoForm.invoiceTitleName = res.titleInfo.InvoiceTitleName;
          this.basicInfoForm.invoiceTitleCreditCode =
            res.titleInfo.InvoiceTitleCreditCode;
          this.basicInfoForm.invoiceTitleBankerNumber =
            res.titleInfo.InvoiceTitleBankerNumber;
          this.basicInfoForm.invoiceTitleBankerName =
            res.titleInfo.InvoiceTitleBankerName;
          this.basicInfoForm.invoiceTitleAddress =
            res.titleInfo.InvoiceTitleAddress;
          this.basicInfoForm.invoiceTitlePhone =
            res.titleInfo.InvoiceTitlePhone;
          // 账户信息
          if (res.ebaseInfoEx) {
            this.basicInfoForm.RegisterNation = res.ebaseInfoEx.RegisterNation;
            this.basicInfoForm.DomesticOverseas =
              res.ebaseInfoEx.DomesticOverseas;
            this.basicInfoForm.CFOName = res.ebaseInfoEx.CFOName;
            this.basicInfoForm.CFOIDCardNo = res.ebaseInfoEx.CFOIDCardNo;
            this.basicInfoForm.CFOIDCardValidDate =
              res.ebaseInfoEx.CFOIDCardValidDate;
            this.basicInfoForm.CFOPhone = res.ebaseInfoEx.CFOPhone;
            this.basicInfoForm.BeneficiaryName =
              res.ebaseInfoEx.BeneficiaryName;
            this.basicInfoForm.BeneficiaryIDCardNo =
              res.ebaseInfoEx.BeneficiaryIDCardNo;
            this.basicInfoForm.BeneficiaryIDCardValidDate =
              res.ebaseInfoEx.BeneficiaryIDCardValidDate;
            this.basicInfoForm.BeneficiaryPhone =
              res.ebaseInfoEx.BeneficiaryPhone;
            this.basicInfoForm.BDAName = res.ebaseInfoEx.BDAName;
            this.basicInfoForm.BDANo = res.ebaseInfoEx.BDANo;
            this.basicInfoForm.BDABankName = res.ebaseInfoEx.BDABankName;
            this.basicInfoForm.BDANumber = res.ebaseInfoEx.BDANumber;
            this.basicInfoForm.BenefitWay = res.ebaseInfoEx.BenefitWay;
            this.basicInfoForm.LegalPersonBankCardNo =
              res.ebaseInfoEx.LegalPersonBankCardNo;
            this.basicInfoForm.BeneficiaryIDCardStartDate =
              res.ebaseInfoEx.BeneficiaryIDCardStartDate;
            this.basicInfoForm.CFOIDCardStartDate =
              res.ebaseInfoEx.CFOIDCardStartDate;
          }
          // 证件照片

          if (res.eInfoEx) {
            res.eInfoEx.forEach((item, index) => {
              if (index >= 9) {
                this.invoiceImgList[index].picUrl = item.ImageURL;
              } else {
                if (index != this.invoiceImgList.length) {
                  if (item.ImageURL) {
                    this.addInvoice();
                    this.invoiceImgList[index].picUrl = item.ImageURL;
                  }
                } else {
                  this.invoiceImgList[index].picUrl = item.ImageURL;
                }
              }
            });
          }
          if (res.eInvoiceTypeList) {
            res.eInvoiceTypeList.forEach((item) => {
              this.selectlist.forEach((select) => {
                if (item.InvoiceTypeName == select.Name) {
                  let TaskName = `${item.TaskTypeName} / ${item.TaskSceneName}`;
                  select.lebel.push(TaskName);
                  select.LicenseName.push(item.LicenseName);
                }
              });
            });
          }
          // 处理受益人列表
          res.eBeneficiary.forEach((item, index) => {
            item.id = Number(index) + 1;
            this.beneficiaryData[index] = item;
          });
        }
      });
    },
  },
  watch: {
    supplyId(val, oldVal) {
      if (val) {
        // 重置其他证件照片
        this.invoiceImgList = [
          {
            loading: false,
            uploaded: false,
            picUrl: "",
            file: "",
          },
        ];
        this.getGoodsOwnerInfo();
      }
    },
  },
  created() {
    // 获取境内/境外
    getDataDict({ type: 35 }).then((res) => {
      this.domestic = res.patterSetInfo;
    });
    // 获取企业性质
    getDataDict({ type: 36 }).then((res) => {
      this.beneficiary = res.patterSetInfo;
    });
    // 获取注册国家
    getDataDict({ type: 37 }).then((res) => {
      this.nationality = res.patterSetInfo;
    });
    // 获取可开票额度
    getDataDict({ type: 29 }).then((res) => {
      this.cities = res.patterSetInfo;
    });
    // 获取发票类型
    getDataDict({ type: 20 }).then((res) => {
      res.patterSetInfo.forEach((select, index) => {
        select.LicenseName = [];
        select.lebel = [];
        this.basicInfoForm[`tos${index}`] = {
          tos: [],
          value: "TaskSceneTypeID",
          label: "label",
          defaultSelect: "请选择业务类型",
          disabled: false,
          lazy: true,
          multiple: true,
          lazyLoad(node, resolve) {
            const { level } = node;
            if (level == 0) {
              getTask({ invoiceType: select.Code }).then((res) => {
                res.TaskTypeList.forEach((item) => {
                  item.label = item.TaskTypeName;
                  item.leaf = level >= 1;
                });
                resolve(res.TaskTypeList);
              });
            } else {
              getTaskScene({
                invoiceType: select.Code,
                TaskTypeID: node.data.TaskTypeID,
              }).then((res) => {
                res.TaskTypeList.forEach((item) => {
                  item.label = item.TaskSceneName;
                  item.leaf = true;
                });
                defaultData = res.TaskTypeList;
                resolve(res.TaskTypeList);
              });
              this.selectlist = res.patterSetInfo;
            }
          },
        };
      });
      this.selectlist = res.patterSetInfo;
      // 获取已填写的信息
      this.getGoodsOwnerInfo();
    });
  },
  components: {
    UploadImg,
    BeneficiaryList,
    upLoadAccessory,
  },
};
</script>

<style lang="scss">
.goodsOwnerManage {
  .el-textarea.is-disabled .el-textarea__inner {
    color: #000;
    background-color: #fff;
  }
  .el-input__inner::-webkit-input-placeholder {
    color: #606266;
  }
  .basucInfoBox .el-input__inner {
    max-width: 200px;
  }
  .basucInfoBox .el-date-editor.el-input {
    width: 200px;
  }
  .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
    font-size: 26px;
    position: relative;
    bottom: -10px;
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/style/variable.scss";
.required {
  font-size: 26px;
  color: #f56c6c;
  position: relative;
  bottom: -10px;
}
.el-form {
  //display: flex;
  //flex-wrap: wrap;
  .el-form-item {
    min-width: 250px;
  }
  .el-date-editor,
  .el-select {
    width: 100%;
  }
}
.accessory {
  width: 100%;
  text-align: center;
}
.imgWrap {
  width: 100%;
  text-align: center;
  .QrCodeUrl {
    width: 230px;
    height: 230px;
    border-radius: 10px;
    margin: 20px;
  }
}
.addDialog {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }
  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
    text-align: center;
  }
  ::v-deep .el-dialog__body {
    padding: 20px;
  }
}
.uploadImgWrap {
  display: flex;
  flex-wrap: wrap;
  .uploadImg {
    margin: 20px;
  }
  .add {
    width: 177px;
    height: 177px;
    line-height: 177px;
    margin: 20px;
    text-align: center;
    border-radius: 5px;
    font-size: 100px;
    cursor: pointer;
    border: 1px dashed $borderGray;
  }
}
.basucInfoBox {
  background: #fff;
  padding: 0 16px 16px 16px;
  height: calc(420vh - 126px);
  .el-alert {
    p {
      margin: 4px 0px;
    }
  }
  .topWrap {
    display: flex;
    .el-button {
      vertical-align: top;
      margin-top: 16px;
      margin-left: 30px;
    }
  }
}
.basucInfoBox::-webkit-scrollbar {
  width: 6px;
}
.basucInfoBox::-webkit-scrollbar-thumb {
  background: #ddd;
}
.content {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .upBox {
    text-align: center;
    .upDiv {
      width: 280px;
      height: 180px;
      cursor: pointer;
    }
    .upText {
      font-size: 14px;
      font-weight: bold;
      margin: 10px 0 0 0;
      color: #333333;
    }
  }
  .el-upload__tip {
    color: $textGray2;
  }
}
.title {
  color: #2081ff;
  position: relative;
  text-indent: 15px;
  font-size: 14px;
  border-bottom: 1px solid $borderGray;
  padding: 20px 0;
  margin-bottom: 20px;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 3px;
    height: 14px;
    border-radius: 5px;
    background: $primary;
  }
}
.upBtn {
  display: flex;
  justify-content: center;
}
.fromBox {
  box-sizing: border-box;
  padding: 20px 0 0 0;
  display: flex;
  justify-content: flex-start;
}
.tabBox {
  height: 42px;
  line-height: 42px;
  margin-bottom: 15px;
  .tabDiv {
    height: 100%;
    overflow: hidden;
    display: flex;
    font-size: 14px;
    color: #303133;
    .placeholder {
      flex: 1;
      border-bottom: 1px solid #e4e7ed;
    }
    .tabView {
      padding: 0 20px;
      border: 1px solid #e4e7ed;
      cursor: pointer;
    }
    .tabViewAvt {
      height: 43px;
      padding: 0 20px;
      border: 1px solid #e4e7ed;
      border-bottom: none;
      cursor: pointer;
      border-bottom: 1px solid transparent;
      color: #409eff;
      border-bottom-color: #fff;
    }
  }
}
</style>
